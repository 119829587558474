import React from "react";
import Redirect404 from "../components/404/Redirect404";
import { Helmet } from "react-helmet";

export default function PageNotFound404() {
  return (
    <>
      <Helmet>
        <link
          rel="conanical"
          href="https://www.KampalaCityGrammarSchool.com/404"
        />
        <title>
          Page Not Found | Kampala City Grammar Nursery, Day and Boarding
          Primary School
        </title>
      </Helmet>
      <Redirect404 />
    </>
  );
}
