import React from "react";
import { Slide } from "react-reveal";
import home from "../../assets/images/content/cta_img.png";

const Registration = () => {
  return (
    <section className="registration section-spacing-80">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="registration-container">
                <div className="reg-icon">
                  <Slide top>
                    <img src={home} alt="Home with Love" />
                  </Slide>
                </div>
                <div className="reg-title">
                  <h3>Enrollment is Going on Now</h3>
                </div>
                <div className="reg-text">
                  <p>
                    Dear Parent/Guardian, you are welcome to Kampala City
                    Grammar Nursery, Day &amp; Primary School.
                  </p>
                </div>
                <div className="reg-cta">
                  <a
                    href="/admission"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button"
                  >
                    Admission Procedures
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Registration;
