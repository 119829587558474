import React from "react";
import { Slide } from "react-reveal";
import EnrollCard from "../reusables/EnrollCard";

const WhyEnrol = () => {
  return (
    <section id="whyenroll" className="enroll section-spacing-80">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide left>
                <div className="section-head center">
                  <h3 className="text-shadow green capitalize">
                    Why enroll your child with us
                  </h3>
                  <p className=" margin-top-20 width-600 margin-auto">
                    Besides your child getting the best education, we have
                    activities and programs of great impact in their lives and
                    character.
                  </p>
                </div>
              </Slide>
              <div className="section-body section-spacing-100">
                <ul className="enroll-container flex">
                  <EnrollCard
                    why="In this school, Every Child Matters"
                    whyText="We organize our teaching basing on the individual child, and put in place programs to support the learning abilities of every pupil, because we believe that every pupil has a potential to learn and achieve. That is why our motto goes; IN GOD WE TRUST."
                  />
                  <EnrollCard
                    why="We offer lifelong education"
                    whyText="The skills imparted to our learners during personal, social, health and leadership education are meant to empower them to succeed and cope with life challenges."
                  />
                  <EnrollCard
                    why="We encourage all our pupils to develop a reading culture"
                    whyText="An opportunity is given to every learner to acquire reading skills through a number of reading activities and lessons. They read a variety of items including newspapers, magazines, novels and by doing research using text books. And E-learning resources."
                  />
                  <EnrollCard
                    why="We have school families "
                    whyText="In this initiative every learner is allocated a ‘teacher-parent’ who works as a personal mentor to the pupil, providing guidance and motivation. The teacher-parents guide learners to set their own goals and work for continuous improvement."
                  />
                  <EnrollCard
                    why="We offer career education"
                    whyText="Our learners are always reminded about the reasons why they study and this is done through career guidance. All teachers engage in career guidance at class, club and house levels. This enables our learners to remain focused and get motivated to studying hard."
                  />
                  <EnrollCard
                    why="We have school vans"
                    whyText="Pupils in the day section can be picked and dropped back home by the school vans. This is done at a cost which is determined by the location and distance of the learner’s home from the school."
                  />
                  <EnrollCard
                    why="Pupils’ welfare and health are a priority"
                    whyText="We have a team of matrons who complement the teachers’ role of taking good care of our learners. We also have a qualified Nurse who take care of pupils with health challenges. She refers cases which can not be handled in the school. Parents are always contacted in case their children need further medical attention. Pupils are fed well on a rich variety of foods that are well distributed in the school menu. We promote health feeding. "
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyEnrol;
