import React from "react";
import { Slide, Fade } from "react-reveal";
import pre from "../../assets/images/content/pre.png";
import pri from "../../assets/images/content/pri.png";
import nur from "../../assets/images/content/nur.png";

const SchoolSections = () => {
  return (
    <section className="sections section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide top>
                <div className="section-head center">
                  <h3 className="text-shadow blue">School Sections</h3>
                  <p className=" margin-top-20 width-600 margin-auto">
                    KCG not only offers affordable and high quality education
                    but also child care services for working parents. Both our
                    Nursery and Primary sections of the school are licensed and
                    have EMIS Numbers; 1A003412 and 2A012972 respectively.
                  </p>
                </div>
              </Slide>
              <div className="section-body section-spacing-60">
                <div className="sections-container white flex">
                  <Fade left>
                    <div className="sections-item light-blue-bg box-shadow">
                      <div className="sections-image">
                        <img src={pre} alt="Preschool section at KCG" />
                      </div>
                      <div className="sections-title">
                        <h4>Preschool</h4>
                      </div>
                      <div className="sections-desc">
                        <p>
                          This serves as the day care center. We receive
                          children from one year of age. We have caring mothers
                          to take care of these children throughout the day.
                        </p>
                      </div>
                    </div>
                  </Fade>
                  <Slide bottom>
                    <div className="sections-item light-green-bg box-shadow">
                      <div className="sections-image">
                        <img src={nur} alt="Preschool section at KCG" />
                      </div>
                      <div className="sections-title">
                        <h4>Nursery</h4>
                      </div>
                      <div className="sections-desc">
                        <p>
                          Here we categorize learners in three groups; the Baby
                          class (reception class, age 2-3), the middle class
                          (age 3-4) and the top class (Preprimary class, 5
                          years).
                        </p>
                      </div>
                    </div>
                  </Slide>
                  <Slide right>
                    <div className="sections-item pink-bg box-shadow">
                      <div className="sections-image">
                        <img src={pri} alt="Preschool section at KCG" />
                      </div>
                      <div className="sections-title">
                        <h4>Primary</h4>
                      </div>
                      <div className="sections-desc">
                        <p>
                          This has graduates from the Nursery section. The
                          primary section has classes from primary one to
                          primary seven.
                        </p>
                      </div>
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SchoolSections;
