import React from "react";

const TestimonialCard = (props) => {
  return (
    <div className="testimonial-card">
      <div className="testimonial-text box-shadow">{props.testimonial}</div>
      <div className="testimonial-user section-spacing-24">
        <div>
          <h4 className=" text-shadow">{props.parent}</h4>
          <p className=" dark-gray margin-top-bottom-10">{props.desc}</p>
        </div>
      </div>
      
    </div>
  );
};

export default TestimonialCard;
