import React from "react";
import GalleryCard from "../reusables/GalleryCard";
import g1 from "../../assets/images/gallery/g1.jpg";
import g2 from "../../assets/images/gallery/g2.jpg";
import g3 from "../../assets/images/gallery/g3.jpg";
import g5 from "../../assets/images/gallery/g5.jpg";
import g6 from "../../assets/images/gallery/g6.jpg";
import g7 from "../../assets/images/gallery/g7.jpg";
import g8 from "../../assets/images/gallery/g8.jpg";
import g9 from "../../assets/images/gallery/g9.jpg";
import g10 from "../../assets/images/gallery/g10.jpg";
import g11 from "../../assets/images/gallery/g11.jpg";
import g12 from "../../assets/images/gallery/g12.jpg";
import q1 from "../../assets/images/staff/IMG-20230714-WA0003.jpg";
import q2 from "../../assets/images/staff/IMG-20230714-WA0042.jpg";
import q3 from "../../assets/images/staff/IMG-20230714-WA0044.jpg";
import q4 from "../../assets/images/staff/IMG-20230714-WA0050.jpg";
import q5 from "../../assets/images/staff/IMG-20230714-WA0053.jpg";
import q6 from "../../assets/images/staff/IMG-20230714-WA0055.jpg";
import q7 from "../../assets/images/staff/IMG-20230714-WA0060.jpg";
import q8 from "../../assets/images/staff/IMG-20230714-WA0061.jpg";
import q10 from "../../assets/images/staff/IMG-20230714-WA0084.jpg";
import q11 from "../../assets/images/staff/IMG-20230714-WA0090.jpg";
import q12 from "../../assets/images/staff/IMG-20230714-WA0093.jpg";
import q13 from "../../assets/images/staff/IMG-20230714-WA0095.jpg";
import q14 from "../../assets/images/staff/IMG-20230714-WA0014.jpg";
import q15 from "../../assets/images/staff/IMG-20230714-WA0015.jpg";
import q16 from "../../assets/images/staff/IMG-20230714-WA0019.jpg";
import a from "../../assets/images/gallery/a.jpg";
import c from "../../assets/images/gallery/c.jpg";
import b from "../../assets/images/gallery/a.jpg";
import d from "../../assets/images/gallery/d.jpg";
import e from "../../assets/images/gallery/e.jpg";
import f from "../../assets/images/gallery/f.jpg";
import g from "../../assets/images/gallery/g.jpg";
import h from "../../assets/images/gallery/h.jpg";
import i from "../../assets/images/gallery/i.jpg";
import j from "../../assets/images/gallery/j.jpg";
import k from "../../assets/images/gallery/k.jpg";
import l from "../../assets/images/gallery/l.jpg";
import m from "../../assets/images/gallery/m.jpg";
import n from "../../assets/images/gallery/n.jpg";
import o from "../../assets/images/gallery/o.jpg";
import p from "../../assets/images/gallery/p.jpg";
import q from "../../assets/images/gallery/q.jpg";
import r from "../../assets/images/gallery/r.jpg";
import s from "../../assets/images/gallery/s.jpg";
import t from "../../assets/images/gallery/t.jpg";
import u from "../../assets/images/gallery/u.jpg";
import v from "../../assets/images/gallery/v.jpg";
import w from "../../assets/images/gallery/w.jpg";
import x from "../../assets/images/gallery/x.jpg";
import y from "../../assets/images/gallery/y.jpg";
import z from "../../assets/images/gallery/z.jpg";
import a1 from "../../assets/images/gallery/a1.jpg";
import a2 from "../../assets/images/gallery/a2.jpg";
import q17 from "../../assets/images/gallery/IMG-20230801-WA0000.jpg";
import q18 from "../../assets/images/gallery/IMG-20230801-WA0001.jpg";
import q19 from "../../assets/images/gallery/IMG-20230801-WA0002.jpg";
import q20 from "../../assets/images/gallery/IMG-20230801-WA0003.jpg";
import q21 from "../../assets/images/gallery/IMG-20230801-WA0004.jpg";
import q22 from "../../assets/images/gallery/IMG-20230801-WA0005.jpg";
import q23 from "../../assets/images/gallery/IMG-20230801-WA0006.jpg";
import q24 from "../../assets/images/gallery/IMG-20230801-WA0007.jpg";
import q25 from "../../assets/images/gallery/IMG-20230801-WA0008.jpg";
import q26 from "../../assets/images/gallery/IMG-20230801-WA0011.jpg";
import b1 from "../../assets/images/gallery/1.jpg";
import b2 from "../../assets/images/gallery/2.jpg";
import b3 from "../../assets/images/gallery/3.jpg";
import b4 from "../../assets/images/gallery/4.jpg";
import b5 from "../../assets/images/gallery/5.jpg";
import b6 from "../../assets/images/gallery/6.jpg";
import b7 from "../../assets/images/gallery/7.jpg";
import b8 from "../../assets/images/gallery/8.jpg";
import b9 from "../../assets/images/gallery/9.jpg";
import b10 from "../../assets/images/gallery/10.jpg";
import b11 from "../../assets/images/gallery/11.jpg";
import b12 from "../../assets/images/gallery/12.jpg";
import b13 from "../../assets/images/gallery/13.jpg";
import b14 from "../../assets/images/gallery/14.jpg";
import b15 from "../../assets/images/gallery/15.jpg";
import b16 from "../../assets/images/gallery/16.jpg";
import b17 from "../../assets/images/gallery/17.jpg";
import b18 from "../../assets/images/gallery/18.jpg";
import b19 from "../../assets/images/gallery/19.jpg";
import b20 from "../../assets/images/gallery/20.jpg";
import b21 from "../../assets/images/gallery/21.jpg";
import b22 from "../../assets/images/gallery/22.jpg";
import b23 from "../../assets/images/gallery/23.jpg";
import b24 from "../../assets/images/gallery/24.jpg";
import b25 from "../../assets/images/gallery/25.jpg";
import b26 from "../../assets/images/gallery/26.jpg";
import b27 from "../../assets/images/gallery/27.jpg";
import b28 from "../../assets/images/gallery/28.jpg";
import b29 from "../../assets/images/gallery/29.jpg";
import b30 from "../../assets/images/gallery/30.jpg";
import b31 from "../../assets/images/gallery/31.jpg";
import b32 from "../../assets/images/gallery/32.jpg";
import b33 from "../../assets/images/gallery/33.jpg";
import b34 from "../../assets/images/gallery/34.jpg";
import b35 from "../../assets/images/gallery/35.jpg";
import b36 from "../../assets/images/gallery/36.jpg";
import b37 from "../../assets/images/gallery/37.jpg";
import b38 from "../../assets/images/gallery/38.jpg";
import b39 from "../../assets/images/gallery/39.jpg";
import b40 from "../../assets/images/gallery/40.jpg";
import b41 from "../../assets/images/gallery/41.jpg";
import f1 from "../../assets/images/gallery/f1.jpg";
import f2 from "../../assets/images/gallery/f2.jpg";
import f5 from "../../assets/images/gallery/f5.jpg";
import f6 from "../../assets/images/gallery/f6.jpg";
import f4 from "../../assets/images/gallery/f4.jpg";
import f7 from "../../assets/images/gallery/f7.jpg";
import f8 from "../../assets/images/gallery/f8.jpg";
import f9 from "../../assets/images/gallery/f9.jpg";
import f11 from "../../assets/images/gallery/f11.jpg";
import l1 from "../../assets/images/p7leavers2024/1.jpg";
import l2 from "../../assets/images/p7leavers2024/2.jpg";
import l3 from "../../assets/images/p7leavers2024/3.jpg";
import l4 from "../../assets/images/p7leavers2024/4.jpg";
import l5 from "../../assets/images/p7leavers2024/5.jpg";
import l6 from "../../assets/images/p7leavers2024/6.jpg";
import l7 from "../../assets/images/p7leavers2024/7.jpg";
import l8 from "../../assets/images/p7leavers2024/8.jpg";
import l9 from "../../assets/images/p7leavers2024/9.jpg";
import l10 from "../../assets/images/p7leavers2024/10.jpg";
import l11 from "../../assets/images/p7leavers2024/11.jpg";
import l12 from "../../assets/images/p7leavers2024/12.jpg";
import l13 from "../../assets/images/p7leavers2024/13.jpg";
import l14 from "../../assets/images/p7leavers2024/14.jpg";
import l15 from "../../assets/images/p7leavers2024/15.jpg";

const GalleryBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-100">
                <div className="padding-16 center">
                  <div className="gallery-container">
                    <GalleryCard
                      galleryImage={l2}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l3}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l4}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l5}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l6}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l7}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l9}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l11}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l13}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l4}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l15}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l1}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l1}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l10}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l12}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={l8}
                      galleryImageCaption="P7 Leaver's Party 2024"
                    />
                    <GalleryCard
                      galleryImage={f1}
                      galleryImageCaption="Fruit Day"
                    />
                    <GalleryCard
                      galleryImage={f2}
                      galleryImageCaption="Fruit Day"
                    />
                    <GalleryCard
                      galleryImage={f5}
                      galleryImageCaption="Fruit Day"
                    />
                    <GalleryCard
                      galleryImage={f7}
                      galleryImageCaption="Fruit Day"
                    />
                    <GalleryCard
                      galleryImage={f8}
                      galleryImageCaption="Fruit Day"
                    />
                    <GalleryCard
                      galleryImage={f9}
                      galleryImageCaption="Fruit Day"
                    />
                    <GalleryCard
                      galleryImage={f11}
                      galleryImageCaption="Fruit Day"
                    />
                    <GalleryCard
                      galleryImage={f6}
                      galleryImageCaption="Fruit Day"
                    />
                    <GalleryCard
                      galleryImage={f4}
                      galleryImageCaption="Fruit Day"
                    />
                    <GalleryCard galleryImage={b1} />
                    <GalleryCard galleryImage={b2} />
                    <GalleryCard galleryImage={b3} />
                    <GalleryCard galleryImage={b4} />
                    <GalleryCard galleryImage={b5} />
                    <GalleryCard galleryImage={b6} />
                    <GalleryCard galleryImage={b7} />
                    <GalleryCard galleryImage={b8} />
                    <GalleryCard galleryImage={b9} />
                    <GalleryCard galleryImage={b10} />
                    <GalleryCard galleryImage={b11} />
                    <GalleryCard galleryImage={b12} />
                    <GalleryCard galleryImage={b13} />
                    <GalleryCard galleryImage={b14} />
                    <GalleryCard galleryImage={b15} />
                    <GalleryCard galleryImage={b16} />
                    <GalleryCard galleryImage={b17} />
                    <GalleryCard galleryImage={b18} />
                    <GalleryCard galleryImage={b19} />
                    <GalleryCard galleryImage={b20} />
                    <GalleryCard galleryImage={b21} />
                    <GalleryCard galleryImage={b22} />
                    <GalleryCard galleryImage={b23} />
                    <GalleryCard galleryImage={b24} />
                    <GalleryCard galleryImage={a} />
                    <GalleryCard galleryImage={b} />
                    <GalleryCard galleryImage={c} />
                    <GalleryCard galleryImage={d} />
                    <GalleryCard galleryImage={e} />
                    <GalleryCard galleryImage={f} />
                    <GalleryCard galleryImage={g} />
                    <GalleryCard galleryImage={h} />
                    <GalleryCard galleryImage={i} />
                    <GalleryCard galleryImage={j} />
                    <GalleryCard galleryImage={k} />
                    <GalleryCard galleryImage={l} />
                    <GalleryCard galleryImage={m} />
                    <GalleryCard galleryImage={n} />
                    <GalleryCard galleryImage={o} />
                    <GalleryCard galleryImage={p} />
                    <GalleryCard galleryImage={q} />
                    <GalleryCard galleryImage={r} />
                    <GalleryCard galleryImage={s} />
                    <GalleryCard galleryImage={t} />
                    <GalleryCard galleryImage={u} />
                    <GalleryCard galleryImage={v} />
                    <GalleryCard galleryImage={w} />
                    <GalleryCard galleryImage={x} />
                    <GalleryCard galleryImage={y} />
                    <GalleryCard galleryImage={z} />
                    <GalleryCard galleryImage={a1} />
                    <GalleryCard galleryImage={a2} />
                    <GalleryCard galleryImage={g1} />
                    <GalleryCard galleryImage={g2} />
                    <GalleryCard galleryImage={g3} />
                    <GalleryCard galleryImage={g8} />
                    <GalleryCard galleryImage={g5} />
                    <GalleryCard galleryImage={g6} />
                    <GalleryCard galleryImage={g7} />
                    <GalleryCard galleryImage={g9} />
                    <GalleryCard galleryImage={g10} />
                    <GalleryCard galleryImage={g11} />
                    <GalleryCard galleryImage={g12} />
                    <GalleryCard galleryImage={q1} />
                    <GalleryCard galleryImage={q2} />
                    <GalleryCard galleryImage={q3} />
                    <GalleryCard galleryImage={q4} />
                    <GalleryCard galleryImage={q5} />
                    <GalleryCard galleryImage={q6} />
                    <GalleryCard galleryImage={q7} />
                    <GalleryCard galleryImage={q8} />
                    <GalleryCard galleryImage={q10} />
                    <GalleryCard galleryImage={q11} />
                    <GalleryCard galleryImage={q12} />
                    <GalleryCard galleryImage={q13} />
                    <GalleryCard galleryImage={q14} />
                    <GalleryCard galleryImage={q15} />
                    <GalleryCard galleryImage={q16} />
                    <GalleryCard galleryImage={q17} />
                    <GalleryCard galleryImage={q18} />
                    <GalleryCard galleryImage={q19} />
                    <GalleryCard galleryImage={q20} />
                    <GalleryCard galleryImage={q21} />
                    <GalleryCard galleryImage={q22} />
                    <GalleryCard galleryImage={q23} />
                    <GalleryCard galleryImage={q24} />
                    <GalleryCard galleryImage={q25} />
                    <GalleryCard galleryImage={q26} />
                    <GalleryCard galleryImage={b25} />
                    <GalleryCard galleryImage={b28} />
                    <GalleryCard galleryImage={b30} />
                    <GalleryCard galleryImage={b31} />
                    <GalleryCard galleryImage={b32} />
                    <GalleryCard galleryImage={b33} />
                    <GalleryCard galleryImage={b34} />
                    <GalleryCard galleryImage={b35} />
                    <GalleryCard galleryImage={b36} />
                    <GalleryCard galleryImage={b37} />
                    <GalleryCard galleryImage={b38} />
                    <GalleryCard galleryImage={b39} />
                    <GalleryCard galleryImage={b40} />
                    <GalleryCard galleryImage={b41} />
                    <GalleryCard galleryImage={b26} />
                    <GalleryCard galleryImage={b27} />
                    <GalleryCard galleryImage={b29} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GalleryBody;
