import React from "react";
import Slider from "react-slick";
import { Slide } from "react-reveal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings1 } from "../reusables/carousel";
import baloon from "../../assets/images/gallery/f.jpg";
import img1 from "../../assets/images/gallery/g7.jpg";
import play from "../../assets/images/gallery/h.jpg";
import study from "../../assets/images/gallery/g12.jpg";
import kidboard from "../../assets/images/gallery/g6.jpg";
import CarCard from "../reusables/CarCard";
import star from "../../assets/images/icons/star.svg";

const HomeHero = () => {
  return (
    <section className="hero">
      <div className="carous">
        <div className="slider-items-container center-slide-arrows">
          <Slider {...settings1}>
            <CarCard bgImg={img1}>
              <Slide top>
                <div className="hero-content-container">
                  <div className="hero-content-inner uppercase center centered">
                    <div className="centered-inner">
                      <div className="best-section">
                        <p className="best-text">welcome to</p>
                        <div className="best-bars-container flex">
                          <div className="best-bar"></div>
                          <div className="flex">
                            <img src={star} className="best-star" />
                            <img src={star} className="best-star" />
                            <img src={star} className="best-star" />
                          </div>
                          <div className="best-bar"></div>
                        </div>
                      </div>
                      <div className="brand-section">
                        <h2 className="best-text">Kampala City Grammar</h2>
                      </div>
                      <div className="slogan-section">
                        <h3 className="best-text">
                          Nursery, Day &amp; Boarding <br></br>{" "}
                          <span className="light-green">Primary School</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={kidboard}>
              <Slide bottom>
                <div className="hero-content-container">
                  <div className="hero-content-inner carousel-sized center centered">
                    <div className="centered-inner">
                      <div className="best-section">
                        <p className="best-text">Kampala city grammar school</p>
                        <div className="best-bars-container flex">
                          <div className="best-bar"></div>
                          <div className="flex">
                            <img src={star} className="best-star" />
                            <img src={star} className="best-star" />
                            <img src={star} className="best-star" />
                          </div>
                          <div className="best-bar"></div>
                        </div>
                      </div>
                      <div className="brand-section">
                        <h2 className="best-text">
                          <span className="white">
                            A <span className="red">Unique</span> Education{" "}
                            <span className="yellow">Community</span>
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={baloon}>
              <Slide bottom>
                <div className="hero-content-container">
                  <div className="hero-content-inner carousel-sized center centered">
                    <div className="centered-inner">
                      <div className="best-section">
                        <p className="best-text">Kampala city grammar school</p>
                        <div className="best-bars-container flex">
                          <div className="best-bar"></div>
                          <div className="flex">
                            <img src={star} className="best-star" />
                            <img src={star} className="best-star" />
                            <img src={star} className="best-star" />
                          </div>
                          <div className="best-bar"></div>
                        </div>
                      </div>
                      <div className="brand-section">
                        <h2 className="best-text">
                          <span className="white">
                            Where <span className="red">your child</span> is our{" "}
                            <span className="yellow">responsibility</span>
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={play}>
              <Slide top>
                <div className="hero-content-container">
                  <div className="hero-content-inner carousel-sized center centered">
                    <div className="centered-inner">
                      <div className="best-section">
                        <p className="best-text">Kampala city grammar school</p>
                        <div className="best-bars-container flex">
                          <div className="best-bar"></div>
                          <div className="flex">
                            <img src={star} className="best-star" />
                            <img src={star} className="best-star" />
                            <img src={star} className="best-star" />
                          </div>
                          <div className="best-bar"></div>
                        </div>
                      </div>
                      <div className="brand-section">
                        <h2 className="best-text">
                          <span className="white">
                            Where <span className="red">learning</span> is a
                            child's <span className="light-blue">Play</span>
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={study}>
              <Slide bottom>
                <div className="hero-content-container">
                  <div className="hero-content-inner carousel-sized center centered">
                    <div className="centered-inner">
                      <div className="best-section">
                        <p className="best-text">Kampala city grammar school</p>
                        <div className="best-bars-container flex">
                          <div className="best-bar"></div>
                          <div className="flex">
                            <img src={star} className="best-star" />
                            <img src={star} className="best-star" />
                            <img src={star} className="best-star" />
                          </div>
                          <div className="best-bar"></div>
                        </div>
                      </div>
                      <div className="brand-section">
                        <h2 className="best-text">
                          <span className="yellow">"In God We Trust"</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
