import React from "react";
import ht from "../../assets/images/gallery/ht.jpg";

const HTMessagesBody = () => {
  return (
    <section className="section-spacing-50 no-padding-top ht">
      <div className="large">
        <div className="section-content-container">
          <div className="section-content whiteBg width-600 box-shadow relative margin-auto border-radius-2">
            <div className="section-body minus-margin-top-100">
              <div className="padding-16">
                <div className="htMessages">
                  <figure>
                    <img src={ht} alt="Kampala City Grammar Head Teacher" />
                    <figcaption>Mr. Ssenabulya Francis</figcaption>
                  </figure>
                  <div className="htMessages-text">
                    <p>
                      Welcome to Kampala City Grammar Nursery, Day and Boarding
                      Primary School, a home of formation of young boys and
                      girls into responsible and God-fearing youths of tomorrow.{" "}
                    </p>
                    <p>
                      A modern private institution started in 2023 with a sole
                      responsibility of offering an inclusive qualitative
                      education through a holistic approach to learning.{" "}
                    </p>
                    <p>
                      Our beautiful school plant is tucked away in a quiet,
                      residential area of Mutundwe after Nalukolongo Market in
                      Lubaga Division. We are designed to maximize
                      pupil-learning and their social – emotional development.
                    </p>
                    <p>
                      {" "}
                      Beyond academics, we offer an opportunity for them to
                      participate in different co-curricular activities to tap
                      their talents and develop their skills in various sporting
                      activities, like Football, netball, swimming, volley ball
                      etc. Their talents and creativity are explored through
                      Music, drama, dancing and poetry.
                    </p>
                    <p>
                      In our school, we appreciate that each learner is distinct
                      and should be given the opportunity to succeed as an
                      individual and no single pupil is left behind.{" "}
                    </p>
                    <p>
                      Our staff is well-qualified and professional. They are
                      dedicated and passionate about working with their
                      learners.{" "}
                    </p>
                    <p>
                      We are very much aware that the work of educating our
                      pupils is not entirely a responsibility of the school
                      alone. We encourage our parents to play their critical
                      role of ensuring that they complement the effort made by
                      the school to see to the success of all the learners.{" "}
                    </p>
                    <p>
                      Please bring your child to the school of this century, to
                      experience learning using personalized learning methods
                      and modern facilities in a conducive environment.{" "}
                    </p>
                    <p>
                      Applications can be picked from school or online.
                      Registration in all classes is on going.
                    </p>
                    <p>Thank You.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HTMessagesBody;
