import React from "react";
import { Slide, Fade } from "react-reveal";
import rou from "../../assets/images/icons/routine.svg";

const Routines = () => {
  return (
    <section className="routines section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide left>
                <div className="section-head center">
                  <h3 className="text-shadow light-blue">
                    School’s Daily Programs and Routines
                  </h3>
                  <p className=" margin-top-20 width-600 margin-auto">
                    We follow orderly and systematic routines that embody our
                    core values to give the best to your child.
                  </p>
                </div>
              </Slide>
              <div className="section-body section-spacing-60">
                <div className="routines-container white width-800 margin-auto">
                  <Slide top>
                    <div className="routine-item routine-1-bg box-shadow">
                      <div className="routine-iconside routine-1-bg-icon">
                        <img src={rou} alt="Routine Icon" />
                      </div>
                      <div className="routine-textside">
                        <h4 className="routine-title">Lessons Begin</h4>
                        <p className="routine-time">8:00 AM : MON-FRI</p>
                        <p className="routine-desc">
                          Lessons begin at 8.00am every day apart from Friday
                          when the school holds an assembly which is mandatory
                          for every member of the school community to attend
                          from 8.00am to 9.00am.
                        </p>
                      </div>
                    </div>
                  </Slide>
                  <Fade right>
                    <div className="routine-item routine-2-bg box-shadow">
                      <div className="routine-iconside routine-2-bg-icon">
                        <img src={rou} alt="Routine Icon" />
                      </div>
                      <div className="routine-textside">
                        <h4 className="routine-title">Lessons End</h4>
                        <p className="routine-time">4:00 PM : MON-FRI</p>
                        <p className="routine-desc">
                          Lessons end at 4.00PM for lower classes. Upper classes
                          (Primary 5 to 7), continue with lessons until 5:30PM
                          and end their day then.
                        </p>
                      </div>
                    </div>
                  </Fade>
                  <Slide bottom>
                    <div className="routine-item routine-3-bg box-shadow">
                      <div className="routine-iconside routine-2-bg-icon">
                        <img src={rou} alt="Routine Icon" />
                      </div>
                      <div className="routine-textside">
                        <h4 className="routine-title">Prep Time</h4>
                        <p className="routine-time">
                          7:00PM-8:00PM &amp; 7:00AM-8:00AM MON-FRI
                        </p>
                        <p className="routine-desc">
                          Pupils in the boarding section attend preps in the
                          evening from 7:00PM to 8:00PM and in the morning from
                          7:00AM to 8:00AM.
                        </p>
                      </div>
                    </div>
                  </Slide>
                  <Slide right>
                    <div className="routine-item routine-4-bg box-shadow">
                      <div className="routine-iconside routine-4-bg-icon">
                        <img src={rou} alt="Routine Icon" />
                      </div>
                      <div className="routine-textside">
                        <h4 className="routine-title">WEDNESDAYS</h4>
                        <p className="routine-time">Co-curricular Time</p>
                        <p className="routine-desc">
                          On Wednesdays all learners get engaged in different
                          club activities like football, netball, swimming etc.
                        </p>
                      </div>
                    </div>
                  </Slide>
                  <Slide top>
                    <div className="routine-item routine-6-bg box-shadow">
                      <div className="routine-iconside routine-6-bg-icon">
                        <img src={rou} alt="Routine Icon" />
                      </div>
                      <div className="routine-textside">
                        <h4 className="routine-title">JUMA PRAYERS</h4>
                        <p className="routine-time">FOR MUSLIMS</p>
                        <p className="routine-desc">
                          The Muslims attend their Juma prayers every Friday.
                        </p>
                      </div>
                    </div>
                  </Slide>
                  <Slide right>
                    <div className="routine-item routine-5-bg box-shadow">
                      <div className="routine-iconside routine-5-bg-icon">
                        <img src={rou} alt="Routine Icon" />
                      </div>
                      <div className="routine-textside">
                        <h4 className="routine-title">SATURDAYS</h4>
                        <p className="routine-time">
                          1:00PM Community work &amp; Skilling
                        </p>
                        <p className="routine-desc">
                          On Saturdays after classes at 1.00PM, pupils have
                          lunch and, in the afternoon, they get involved in
                          community work and other skilling activities.
                        </p>
                      </div>
                    </div>
                  </Slide>
                  <Slide left>
                    <div className="routine-item routine-8-bg box-shadow">
                      <div className="routine-iconside routine-8-bg-icon">
                        <img src={rou} alt="Routine Icon" />
                      </div>
                      <div className="routine-textside">
                        <h4 className="routine-title">SUNDAYS</h4>
                        <p className="routine-time">FOR CHRISTIANS</p>
                        <p className="routine-desc">
                          When a priest is not invited into the school, pupils
                          attend mass and services in the churches near the
                          school.
                        </p>
                      </div>
                    </div>
                  </Slide>
                  <Slide bottom>
                    <div className="routine-item routine-7-bg box-shadow">
                      <div className="routine-iconside routine-7-bg-icon">
                        <img src={rou} alt="Routine Icon" />
                      </div>
                      <div className="routine-textside">
                        <h4 className="routine-title">SUNDAYS AFTERNOON</h4>
                        <p className="routine-time">FREE ACTIVITY</p>
                        <p className="routine-desc">
                          Sunday afternoons are free activity moments and
                          learners prepare themselves for the week.
                        </p>
                      </div>
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Routines;
