import React from "react";
import { Slide } from "react-reveal";
import Fees from "../reusables/Fees";

const FeesBody = () => {
  return (
    <section className="section-spacing-50 no-padding-top">
      <div className="large">
        <div className="section-content-container">
          <div className="section-content whiteBg width-960 box-shadow relative margin-auto border-radius-2">
            <div className="section-body minus-margin-top-100">
              <div className="padding-16">
                <div className="section-content">
                  <Slide left>
                    <div className="section-head center">
                      <h3 className="text-shadow light-blue">
                        School Fees Structure
                      </h3>
                      <p className=" margin-top-20 width-600 margin-auto">
                        Our fees are pocket friendly and affordable. You get
                        value for money when you consider the quality of
                        education your child is to receive while with us, the
                        meals given and the care received by all learners.
                      </p>
                    </div>
                  </Slide>
                  <Fees />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeesBody;
