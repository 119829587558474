import React from "react";
import GalleryCard from "../reusables/GalleryCard";
import p1 from "../../assets/images/prefects2023/_Q3A6310.jpg";
import p2 from "../../assets/images/prefects2023/_Q3A6312.jpg";
import p3 from "../../assets/images/prefects2023/_Q3A6313.jpg";
import p4 from "../../assets/images/prefects2023/_Q3A6314.jpg";
import p5 from "../../assets/images/prefects2023/_Q3A6315.jpg";
import p6 from "../../assets/images/prefects2023/_Q3A6319.jpg";
import p7 from "../../assets/images/prefects2023/_Q3A6320.jpg";
import p8 from "../../assets/images/prefects2023/_Q3A6322.jpg";
import p9 from "../../assets/images/prefects2023/_Q3A6326.jpg";
import p10 from "../../assets/images/prefects2023/_Q3A6328.jpg";
import p11 from "../../assets/images/prefects2023/_Q3A6331.jpg";
import p12 from "../../assets/images/prefects2023/_Q3A6332.jpg";
import p13 from "../../assets/images/prefects2023/_Q3A6337.jpg";
import p14 from "../../assets/images/prefects2023/_Q3A6339.jpg";
import p15 from "../../assets/images/prefects2023/_Q3A6340.jpg";
import p16 from "../../assets/images/prefects2023/_Q3A6344.jpg";
import p17 from "../../assets/images/prefects2023/_Q3A6348.jpg";
import p18 from "../../assets/images/prefects2023/_Q3A6350.jpg";
import p19 from "../../assets/images/prefects2023/_Q3A6385.jpg";
import p20 from "../../assets/images/prefects2023/_Q3A6516.jpg";

const PrefectsBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-100">
                <div className="padding-16 center">
                  <div className="gallery-container">
                    <GalleryCard galleryImage={p1} />
                    <GalleryCard galleryImage={p2} />
                    <GalleryCard galleryImage={p3} />
                    <GalleryCard galleryImage={p4} />
                    <GalleryCard galleryImage={p5} />
                    <GalleryCard galleryImage={p6} />
                    <GalleryCard galleryImage={p7} />
                    <GalleryCard galleryImage={p8} />
                    <GalleryCard galleryImage={p9} />
                    <GalleryCard galleryImage={p10} />
                    <GalleryCard galleryImage={p11} />
                    <GalleryCard galleryImage={p12} />
                    <GalleryCard galleryImage={p13} />
                    <GalleryCard galleryImage={p14} />
                    <GalleryCard galleryImage={p15} />
                    <GalleryCard galleryImage={p16} />
                    <GalleryCard galleryImage={p17} />
                    <GalleryCard galleryImage={p18} />
                    <GalleryCard galleryImage={p19} />
                    <GalleryCard galleryImage={p20} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrefectsBody;
