import React from "react";

const GalleryCard = (props) => {
  return (
    <figure>
      <img
        src={props.galleryImage}
        alt={props.galleryImageCaption}
        className="image box-shadow"
      />
      <figcaption>{props.galleryImageCaption}</figcaption>
    </figure>
  );
};

export default GalleryCard;
