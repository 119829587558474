import React from "react";
import sp from "../../assets/images/content/sp.jpg";
import events from "../../assets/images/updates/upcoming.jpg";

const UpdatesBody = () => {
  return (
    <section className="section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content white-bg box-shadow width-600 relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-100">
                <div className="padding-16 center">
                  <div className="updates-container">
                    <div className="updates-card box-shadow">
                      <div className="updates-head center">
                        <h3 className="uppercase green">
                          {" "}
                          UPCOMING SCHOOL EVENTS TO END OF SCHOOL TERM{" "}
                        </h3>
                      </div>
                      <div className="updates-image">
                        <img
                          src={events}
                          alt="Spelling Bee Competitions 2023"
                        />
                      </div>
                      <div className="updates-details">
                        <h4 className="uppercase green margin-top-bottom-10">
                          Details
                        </h4>
                        <div className="updates-details-inner">
                          <ul>
                            <li>
                              Date: <strong>14th October 2024</strong>
                            </li>
                          </ul>
                          <p>
                            The above table outline has the pending school
                            events, please take note of them and participate
                            accordingly. We shall be sharing updates and how
                            they went on our social media pages as well. Thank You!
                          </p>
                          <a
                            href="https://instagram.com/kampala_city_grammar_school"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            KCG School on Instagram
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="updates-card box-shadow">
                      <div className="updates-head center">
                        <h3 className="uppercase green">
                          {" "}
                          Spelling Bee Competitions 2023{" "}
                        </h3>
                      </div>
                      <div className="updates-image">
                        <img src={sp} alt="Spelling Bee Competitions 2023" />
                      </div>
                      <div className="updates-details">
                        <h4 className="uppercase green margin-top-bottom-10">
                          Details
                        </h4>
                        <div className="updates-details-inner">
                          <ul>
                            <li>
                              Date: <strong>23rd July 2023</strong>
                            </li>
                          </ul>
                          <p>
                            Kampala City Grammar School organizes Spelling
                            competitions anually and pupils participate by
                            classes.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdatesBody;
