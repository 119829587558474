import React from "react";

export default function Redirect404() {
  return (
    <section className="section threads-bg">
      <div className="container">
        <div className="large">
          <div className="fullscreen center">
            <div className="centered-inner">
              <div className="title-container">
                <h3 className="title bold-title">Page Not Found</h3>
              </div>
              <div className="subtitle-container section-spacing-40">
                <p>But you are not lost.</p>
                <p>We can take you home</p>
              </div>
              <div className="button-container section-spacing-10">
                <a href="/" className="button green-button bold white">
                  Lets go Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
