import React from "react";
import GalleryCard from "../reusables/GalleryCard";
import ht from "../../assets/images/gallery/ht.jpg";
import t1 from "../../assets/images/staff/IMG-20230714-WA0005.jpg";
import t2 from "../../assets/images/staff/IMG-20230714-WA0006.jpg";
import t3 from "../../assets/images/staff/IMG-20230714-WA0007.jpg";
import t4 from "../../assets/images/staff/IMG-20230714-WA0008.jpg";
import t5 from "../../assets/images/staff/IMG-20230714-WA0009.jpg";
import t6 from "../../assets/images/staff/IMG-20230714-WA0010.jpg";
import t7 from "../../assets/images/staff/IMG-20230714-WA0011.jpg";
import t8 from "../../assets/images/staff/IMG-20230714-WA0012.jpg";
import t9 from "../../assets/images/staff/IMG-20230714-WA0013.jpg";
import t10 from "../../assets/images/staff/IMG-20230714-WA0016.jpg";
import t11 from "../../assets/images/staff/IMG-20230714-WA0017.jpg";
import t12 from "../../assets/images/staff/IMG-20230714-WA0018.jpg";
import t13 from "../../assets/images/staff/IMG-20230714-WA0022.jpg";
import g44 from "../../assets/images/gallery/g4.jpg";
import o from "../../assets/images/gallery/o.jpg";
import q from "../../assets/images/gallery/q.jpg";
import x from "../../assets/images/gallery/x.jpg";
import s1 from "../../assets/images/staff/1.jpg";
import s2 from "../../assets/images/staff/2.jpg";
import s3 from "../../assets/images/staff/3.jpg";
import s4 from "../../assets/images/staff/4.jpg";
import s5 from "../../assets/images/staff/5.jpg";
import s6 from "../../assets/images/staff/6.jpg";
import s7 from "../../assets/images/staff/7.jpg";
import s8 from "../../assets/images/staff/8.jpg";
import s9 from "../../assets/images/staff/9.jpg";
import s10 from "../../assets/images/staff/10.jpg";
import s11 from "../../assets/images/staff/11.jpg";
import s12 from "../../assets/images/staff/12.jpg";
import s13 from "../../assets/images/staff/13.jpg";

const StaffBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content white-bg box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-100">
                <div className="padding-16 center">
                  <div className="gallery-container">
                    <GalleryCard
                      galleryImage={ht}
                      galleryImageCaption="Head Teacher"
                    />
                    <GalleryCard galleryImage={g44} />
                    <GalleryCard galleryImage={t1} />
                    <GalleryCard galleryImage={o} />
                    <GalleryCard galleryImage={q} />
                    <GalleryCard galleryImage={x} />
                    <GalleryCard galleryImage={t2} />
                    <GalleryCard galleryImage={t3} />
                    <GalleryCard galleryImage={t4} />
                    <GalleryCard galleryImage={t5} />
                    <GalleryCard galleryImage={t6} />
                    <GalleryCard galleryImage={t7} />
                    <GalleryCard galleryImage={t8} />
                    <GalleryCard galleryImage={t9} />
                    <GalleryCard galleryImage={t10} />
                    <GalleryCard galleryImage={t11} />
                    <GalleryCard galleryImage={t12} />
                    <GalleryCard galleryImage={t13} />
                    <GalleryCard galleryImage={s1} />
                    <GalleryCard galleryImage={s2} />
                    <GalleryCard galleryImage={s3} />
                    <GalleryCard galleryImage={s4} />
                    <GalleryCard galleryImage={s5} />
                    <GalleryCard galleryImage={s6} />
                    <GalleryCard galleryImage={s7} />
                    <GalleryCard galleryImage={s8} />
                    <GalleryCard galleryImage={s9} />
                    <GalleryCard galleryImage={s10} />
                    <GalleryCard galleryImage={s11} />
                    <GalleryCard galleryImage={s12} />
                    <GalleryCard galleryImage={s13} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StaffBody;
