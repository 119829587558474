import React from "react";
import { Slide } from "react-reveal";
import Fees from "../reusables/Fees";

const FeesStructure = () => {
  return (
    <section className="fees-structure section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide left>
                <div className="section-head center">
                  <h3 className="text-shadow light-blue">
                    School Fees Structure
                  </h3>
                  <p className=" margin-top-20 width-600 margin-auto">
                    Our fees are pocket friendly and affordable. You get value
                    for money when you consider the quality of education your
                    child is to receive while with us, the meals given and the
                    care received by all learners.
                  </p>
                </div>
              </Slide>
              <Fees />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeesStructure;
