import React from "react";
import tick from "../../assets/images/icons/check.svg";
import { Slide } from "react-reveal";

const About = () => {
  return (
    <section className="about section-spacing-80">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="shared-section flex">
                <div className="textside">
                  <Slide top>
                    <div className="section-head">
                      <h3 className="uppercase margin-top-bottom-10 text-shadow green">
                        About Kampala City Grammar School
                      </h3>
                    </div>
                  </Slide>
                  <div className="section-body">
                    <p>
                      Kampala City Grammar Nursery, Day and Boarding Primary
                      School is located within Mutundwe residential area in a
                      quiet and safe environment. We are a Unique Educational
                      Community where learners enjoy a Modern and Personalized
                      approach to Education.{" "}
                    </p>
                    <ul>
                      <Slide left>
                        <li>
                          <img
                            src={tick}
                            alt="Check Icon"
                            className="about_icon"
                          />
                          <div>
                            <h4>Foundation</h4>
                            <p>
                              The school was founded in 2023 and it is based on
                              a Christian background with a lot of emphasis on a
                              holistic upbringing of our learners, as God
                              fearing, respectful and academically sound.
                            </p>
                          </div>
                        </li>
                      </Slide>
                      <Slide right>
                        <li>
                          <img
                            src={tick}
                            alt="Check Icon"
                            className="about_icon"
                          />
                          <div>
                            <h4>Inclusivity</h4>
                            <p>
                              Much as the School is founded on a Catholic
                              background, other main stream Religions, like
                              Anglican and Islam are respected and pupils who
                              belong to those religious denominations are
                              granted time to worship and receive religious
                              instructions accordingly.{" "}
                            </p>
                          </div>
                        </li>
                      </Slide>
                      <Slide top>
                        {" "}
                        <li>
                          <img
                            src={tick}
                            alt="Check Icon"
                            className="about_icon"
                          />
                          <div>
                            <h4>School sections</h4>
                            <p>
                              The school has 3 sections of Preschool, Nursery
                              and Primary section to maximize the academic
                              foundation of your child. Both Nursery and Primary
                              sections of the school are licensed and have EMIS
                              Numbers; 1A003412 and 2A012972 respectively.
                            </p>
                          </div>
                        </li>
                      </Slide>
                      <Slide bottom>
                        <li>
                          <img
                            src={tick}
                            alt="Check Icon"
                            className="about_icon"
                          />
                          <div>
                            <h4>Enrollment</h4>
                            <p>
                              The school as of 2023 has a total enrollment
                              number of 383 happy and loved pupils. 92 of whom
                              are in the Nursery section and 251 of them in the
                              Primary section. There are 42 boys and 46 girls in
                              the Nursery section and 150 boys and 101 girls in
                              the primary section.
                            </p>
                          </div>
                        </li>
                      </Slide>
                    </ul>
                  </div>
                </div>
                <div className="imgside"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
