import React from "react";
import { Slide, Fade } from "react-reveal";
import bull from "../../assets/images/icons/bull.svg";
import eye from "../../assets/images/icons/eye.svg";
import motto from "../../assets/images/icons/diamond.svg";

const Motto = () => {
  return (
    <section>
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className=" minus-margin-top-100 relative">
                <div className="mottos-container flex">
                  <Fade left>
                    <div className="motto-item">
                      <div className="motto-head">
                        <img src={motto} alt="" />
                        <h3>School motto</h3>
                      </div>
                      <div className="motto-body">
                        <p>“In God we Trust.”</p>
                      </div>
                    </div>
                  </Fade>
                  <Slide top>
                    <div className="motto-item">
                      <div className="motto-head">
                        <img src={eye} alt="" />
                        <h3>School Vision</h3>
                      </div>
                      <div className="motto-body">
                        <p>
                          To develop learners’ curiosity, their interest and
                          love for learning.
                        </p>
                      </div>
                    </div>
                  </Slide>
                  <Fade right>
                    <div className="motto-item">
                      <div className="motto-head">
                        <img src={bull} alt="" />
                        <h3>School Mission</h3>
                      </div>
                      <div className="motto-body">
                        <p>
                          To provide high quality education and childcare in a
                          safe, respectful and inclusive environment that builds
                          a foundation for lifelong learning.
                        </p>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Motto;
