import React from "react";
import map from "../../assets/images/icons/map.svg";
import email from "../../assets/images/icons/email.svg";
import phone from "../../assets/images/icons/phone.svg";
import fb from "../../assets/images/icons/facebook.png";
import twi from "../../assets/images/icons/twitter.png";
import wa from "../../assets/images/icons/whatsapp.png";

const DirectionBody = () => {
  return (
    <section className="section-spacing-50 no-padding-top">
      <div className="large">
        <div className="section-content-container">
          <div className="section-content whiteBg width-960 box-shadow relative margin-auto border-radius-2">
            <div className="section-body minus-margin-top-100">
              <div className="padding-16">
                <div className="address-wrap flex">
                  <div className="map">
                    <h3 className="text-shadow">
                      Map Direction to Kampala City Grammar School
                    </h3>
                    <div className="map-container width-100">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d127672.55285433852!2d32.46881668598083!3d0.2898226834541538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x177dbd18b61bd3fb%3A0x4d56f1fb5d7437aa!2sKampala!3m2!1d0.28975!2d32.5388248!5e0!3m2!1sen!2sug!4v1689531505332!5m2!1sen!2sug"
                        style={{ border: 0 }}
                        allowfullscreen="true"
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                  <div className="address-office">
                    <div>
                      <h3 className="text-shadow">Office &amp; Contacts</h3>
                      <div className="contacts-container">
                        <div className="contacts-item">
                          <div className="contacts-icon">
                            <img src={map} alt="Map Icon" />
                          </div>
                          <div className="contacts-text">
                            <h4>Location</h4>
                            <p className=" margin-top-10">
                              Mutundwe Via Nalukolongo Market, Lubaga Division,
                              Kampala, Uganda
                            </p>
                          </div>
                        </div>
                        <div className="contacts-item">
                          <div className="contacts-icon">
                            <img src={phone} alt="Call Icon" />
                          </div>
                          <div className="contacts-text">
                            <h4>Phone</h4>
                            <p className=" margin-top-10">
                              +256 705 640 880 | +256 393 255 652
                            </p>
                          </div>
                        </div>
                        <div className="contacts-item">
                          <div className="contacts-icon">
                            <img src={email} alt="Email Icon" />
                          </div>
                          <div className="contacts-text">
                            <h4>Email &amp; Post Office</h4>
                            <p className=" margin-top-10">
                              kampalacitygrammar@outlook.com | P.O Box 112646,
                              Kampala Uganda
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="address-message flex section-spacing-30">
                  <a href="#" className="button">
                    Get in Touch
                  </a>
                  <div className="social-icons flex">
                    <a
                      href="https://wa.me/256772402260"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={wa} alt="WhatsApp Icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DirectionBody;
