import React from "react";
const PageHero = (props) => {
  return (
    <section className={props.classes}>
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="page-hero centered">
              <div className=" centered-inner">
                <div className="title-container">
                  <h1 className={props.titleClasses}>{props.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageHero;
