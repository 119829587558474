import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import PageNotFound404 from "./pages/PageNotFound404";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import KCGGallery from "./pages/KCGGallery";
import KCGStaff from "./pages/KCGStaff";
import AdmissionProcedure from "./pages/AdmissionProcedures";
import Directions from "./pages/Directions";
import Fees from "./pages/Fees";
import AboutKCG from "./pages/AboutKCG";
import Calendar from "./pages/Calendar";
import HTMessages from "./pages/HTMessages";
import UpdatesKCG from "./pages/UpdatesKCG";
import KCGPrefects from "./pages/KCGPrefects";

export default class App extends React.PureComponent {
  render() {
    return (
      <Router>
        <div className="app" id="page">
          <a href="#content" className="skip-to-content-link">
            Skip to Content
          </a>
          <Header />
          <div className="site-content" id="content">
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/gallery">
                <KCGGallery />
              </Route>
              <Route exact path="/kcg-staff">
                <KCGStaff />
              </Route>
              <Route exact path="/kcg-prefects">
                <KCGPrefects />
              </Route>
              <Route exact path="/contact">
                <Directions />
              </Route>
              <Route exact path="/fees-structure">
                <Fees />
              </Route>
              <Route exact path="/admission">
                <AdmissionProcedure />
              </Route>
              <Route exact path="/about">
                <AboutKCG />
              </Route>
              <Route exact path="/updates">
                <UpdatesKCG />
              </Route>
              <Route exact path="/headteacher-messages">
                <HTMessages />
              </Route>
              <Route exact path="/calendar">
                <Calendar />
              </Route>
              <Route exact path="/404">
                <PageNotFound404 />
              </Route>

              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}
