import React from "react";

const AdmissionBody = () => {
  return (
    <section className=" section-spacing-50 no-padding-top">
      <div className="large">
        <div className="section-content-container">
          <div className="section-content white-bg width-600 box-shadow relative margin-auto border-radius-2">
            <div className="section-body minus-margin-top-100">
              <div className="padding-16">
                <div className="procedures-container">
                  <div className="step">
                    <div className="step-head">
                      <p>STEP 1</p>

                      <h2>
                        Fill and complete the application form and submit it
                      </h2>
                    </div>
                    <div className="step-body">
                      <p>
                        Parents are required to fill application form for new
                        pupils in the school.
                      </p>
                    </div>
                  </div>
                  <div className="step">
                    <div className="step-head">
                      <p>STEP 2</p>

                      <h2>Child is invited for a quick assessment interview</h2>
                    </div>
                    <div className="step-body">
                      <p>
                        The interview is meant to physically meet the
                        prospective learner and assess his or her fitness for
                        the level applied for.
                      </p>
                    </div>
                  </div>
                  <div className="step">
                    <div className="step-head">
                      <p>STEP 3</p>

                      <h2>Pay admission fee and get addmission letter</h2>
                    </div>
                    <div className="step-body">
                      <p>
                        On satisfying the interviewer, the parent is asked to
                        pay the admission fee (50,000 Ugx) and is given the
                        admission letter together with the medical examination
                        form and the school requirements list.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="white-bg">
                  <h3 className="center margin-bottom-10">NOTE:</h3>
                  <p>
                    (i) <br></br> The medical form should be completed by a
                    genuine physician and it is returned to school for filing.
                    Some times a team of doctors are invited into the school to
                    conduct those medical examinations at the beginning of every
                    school term.{" "}
                  </p>
                  <p className=" margin-top-20">
                    (ii)<br></br> To make it easier for the parents, most of the
                    items are secured from the school. Such items include;
                    exercise books, truck suits, T-shirts, bed sheets, and all
                    uniforms.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdmissionBody;
