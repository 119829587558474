import React from "react";
import { Slide } from "react-reveal";

const Fees = () => {
  return (
    <div className="section-body section-spacing-60 no-padding-top width-800 margin-auto">
      <div className="fees-tables-container">
        <Slide left>
          <div className="tuition fees-table-item">
            <h3 className="green">School Fees</h3>
            <table className="box-shadow tution-table fees-table">
              <tr>
                <th>Section</th>
                <th>Day</th>
                <th>Boarding</th>
              </tr>
              <tr>
                <td>Nursery</td>
                <td>800,000 Ugshs</td>
                <td>1,200,000 Ugshs</td>
              </tr>
              <tr>
                <td>Primary</td>
                <td>850,000 Ugshs</td>
                <td>1,200,000 Ugshs</td>
              </tr>
            </table>
          </div>
        </Slide>
        <Slide top>
          <div className="uniforms fees-table-item">
            <h3 className="blue">Uniforms</h3>
            <table className="box-shadow uniforms-table fees-table">
              <tr>
                <th>Kindergaten</th>
                <th>Primary Day</th>
                <th>Primary Boarding</th>
              </tr>
              <tr>
                <td>
                  <table>
                    <tr>
                      <th id="nested">Boys</th>
                      <th id="nested">Girls</th>
                    </tr>
                    <tr>
                      <td>349,000 Ugshs</td>
                      <td>349,000 Ugshs</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table>
                    <tr>
                      <th id="nested">Boys</th>
                      <th id="nested">Girls</th>
                    </tr>
                    <tr>
                      <td>420,000 Ugshs</td>
                      <td>420,000 Ugshs</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table>
                    <tr>
                      <th id="nested">Boys</th>
                      <th id="nested">Girls</th>
                    </tr>
                    <tr>
                      <td>474,000 Ugshs</td>
                      <td>474,000 Ugshs</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <div className="table-caption margin-top-10">
              <p className="margin-bottom-10">
                <span className="bold right-push-4">Kindergatten (Girls):</span>
                <span>
                  1 Dress, 1 Sweater, 2 pairs of stockings, 1 Track suit, 1
                  T-shirt &amp; Sports wear
                </span>
              </p>
              <p className="margin-bottom-10">
                <span className="bold right-push-4">Kindergatten (Boys):</span>
                <span>
                  1 Shirt, 1 pair of shorts, 1 Sweater, 2 pairs of stockings, 1
                  Track suit, 1 T-shirt &amp; Sports wear
                </span>
              </p>
              <p className="margin-bottom-10">
                <span className="bold right-push-4">Primary Day (Girls):</span>
                <span>
                  2 Shirts, 1 dress, 1 Sweater, 2 pairs of stockings, 1 Track
                  suit, 1 Tie, 1 T-shirt &amp; Sports wear
                </span>
              </p>
              <p className="margin-bottom-10">
                <span className="bold right-push-4">Primary Day (Boys):</span>
                <span>
                  2 Shirts, 1 pair of shorts, 1 Sweater, 2 pairs of stockings, 1
                  Track suit, 1 Tie, 1 belt, 1 T-shirt &amp; Sports wear
                </span>
              </p>
              <p className="margin-bottom-10">
                <span className="bold right-push-4">
                  Primary Boarding (Girls):
                </span>
                <span>
                  2 Shirts, 1 dress, 1 Sweater, 2 pairs of stockings, 1 Track
                  suit, 1 Tie, 2 casual dresses, 1 T-shirt &amp; Sports wear
                </span>
              </p>
              <p className="margin-bottom-10">
                <span className="bold right-push-4">Primary Day (Boys):</span>
                <span>
                  2 Shirts, 1 pair of shorts, 1 Sweater, 2 pairs of stockings, 1
                  Track suit, 1 Tie, 1 belt, 2 pairs casual wear, 1 T-shirt &amp; Sports wear
                </span>
              </p>
            </div>
          </div>
        </Slide>
        <Slide right>
          <div className="cocur fees-table-item">
            <h3 className="red">Special co-curricular lessons (optional) </h3>
            <table className="box-shadow cocur-table fees-table">
              <tr>
                <th>Ballet</th>
                <th>Scouts</th>
                <th>Football</th>
                <th>Swimming</th>
                <th>Sports Jersey</th>
              </tr>
              <tr>
                <td>100,000 Ugshs</td>
                <td>50,000 Ugshs</td>
                <td>50,000 Ugshs</td>
                <td>50,000 Ugshs</td>
                <td>52,000 Ugshs</td>
              </tr>
            </table>
          </div>
        </Slide>
      </div>
      <p className="uppercase center bold">
        Admission fees &amp; Interview to all classes: 100,000 Ugshs{" "}
      </p>
    </div>
  );
};

export default Fees;
