import React from "react";
import { Helmet } from "react-helmet";
import AboutBody from "../components/about/AboutBody";
import PageHero from "../components/reusables/hero/PageHero";

export default function AboutKCG() {
  return (
    <>
      <Helmet>
        <link
          rel="conanical"
          href="https://www.KampalaCityGrammarSchool.com/about"
        />
        <meta
          name="description"
          content="A Unique Educational Community where learners enjoy a Modern and Personalized approach to Education.  "
        />
        <meta
          name="og:title"
          content="About | Kampala City Grammar Nursery, Day and Boarding Primary School"
        />
        <meta name="og:url" content="https://KampalaCityGrammarSchool.com/about" />
        <meta
          name="og:description"
          content="A Unique Educational Community where learners enjoy a Modern and Personalized approach to Education.  "
        />
        <meta
          name="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1689752469/g7_nskbfi.jpg"
        />
        <meta
          name="twitter:title"
          content="About | Kampala City Grammar Nursery, Day and Boarding Primary School"
        />
        <meta
          name="twitter:text:title"
          content="About | Kampala City Grammar Nursery, Day and Boarding Primary School"
        />
        <meta
          name="twitter:description"
          content="A Unique Educational Community where learners enjoy a Modern and Personalized approach to Education.  "
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1689752469/g7_nskbfi.jpg"
        />

        <title>
          About | Kampala City Grammar Nursery, Day and Boarding Primary School
        </title>
      </Helmet>
      <PageHero
        classes="footerImgBg"
        titleClasses="title uppercase crimson"
        title="About KCG School"
      />
      <AboutBody />
    </>
  );
}
