import React from "react";
import Slider from "react-slick";
import { Slide } from "react-reveal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings2 } from "../reusables/testCarousel";
import TestimonialCard from "../reusables/TestimonialCard";

const Testimonials = () => {
  return (
    <section className="testimonials section-spacing-80">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide left>
                <div className="section-head center">
                  <h3 className="text-shadow light-blue">
                    What Parents are saying
                  </h3>
                  <p className=" margin-top-20 width-600 margin-auto">
                    We are glad to know that the parents of our pupils are as
                    happy and glad to entrust their kids with us.
                  </p>
                </div>
              </Slide>
              <div className="section-body section-spacing-60">
                <div className="testimonials-container slider-items-container">
                  <Slider {...settings2}>
                    <TestimonialCard
                      testimonial="I wish to first of all thank you for the time and the warm welcome you gave to us when we came to your School. We were impressed with the whole experience and for me personally for the fact that the school has such an amazing and conducive environment for a learner."
                      parent="Nannyonga Christine"
                      desc="Parent of Makumbi Phillip - P.6"
                    />
                    <TestimonialCard
                      testimonial="My wife and I, we confirm we have our son, Aron, at the right school that is important in preparing him for the kind of a man we envision. Thank Kampala City Grammar School for being a wonderful education center."
                      parent="Dr. Mukama Anthony"
                      desc="Parent of Aron Mukama - P.4"
                    />
                    <TestimonialCard
                      testimonial="We made the right choice of a school for our daughter. We were impressed by all the experience from Pre-school and we loved to continue keeping our daughter at Kampala City Grammar School. That is all I can say for now."
                      parent="Komugisha Annet"
                      desc="Parent of Elizabeth Kukundakwe - P.1"
                    />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
