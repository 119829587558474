import React from "react";

const EnrollCard = (props) => {
  return (
    <li className="enroll-item box-shadow">
      <h4>{props.why}</h4>
      <p>{props.whyText}</p>
    </li>
  );
};

export default EnrollCard;
