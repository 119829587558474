import React from "react";

const CarCard = (props) => {
  return (
    <div className="carousel-content-container relative">
      <div className="carous-image">
        <img src={props.bgImg} />
      </div>
      <div className="carousel-content">
        <div className="container">
          <div className="large">
            <div className="section-content-container">
              <div className="section-content hero-content">
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarCard;
