import React from "react";
import { Helmet } from "react-helmet";
import About from "../components/home/About";
import Address from "../components/home/Address";
import Enrollment from "../components/home/Enrollment";
import FeesStructure from "../components/home/FeesStructure";
import HomeHero from "../components/home/HomeHero";
import Motto from "../components/home/Motto";
import Registration from "../components/home/Registration";
import Routines from "../components/home/Routines";
import SchoolSections from "../components/home/SchoolSections";
import Testimonials from "../components/home/Testimonials";
import WhyEnrol from "../components/home/WhyEnrol";

export default function Home() {
  return (
    <>
      <Helmet>
        <link
          rel="conanical"
          href="https://www.KampalaCityGrammarSchool.com/"
        />
        <meta
          name="description"
          content="A Unique Educational Community where learners enjoy a Modern and Personalized approach to Education.  "
        />
        <meta
          name="og:title"
          content="Home | Kampala City Grammar Nursery, Day and Boarding Primary School"
        />
        <meta name="og:url" content="https://KampalaCityGrammarSchool.com/" />
        <meta
          name="og:description"
          content="A Unique Educational Community where learners enjoy a Modern and Personalized approach to Education.  "
        />
        <meta
          name="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1689752469/g7_nskbfi.jpg"
        />
        <meta
          name="twitter:title"
          content="Home | Kampala City Grammar Nursery, Day and Boarding Primary School"
        />
        <meta
          name="twitter:text:title"
          content="Home | Kampala City Grammar Nursery, Day and Boarding Primary School"
        />
        <meta
          name="twitter:description"
          content="A Unique Educational Community where learners enjoy a Modern and Personalized approach to Education.  "
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1689752469/g7_nskbfi.jpg"
        />

        <title>
          Home | Kampala City Grammar Nursery, Day and Boarding Primary School
        </title>
      </Helmet>
      <HomeHero />
      <Motto />
      <About />
      <SchoolSections />
      <Routines />
      <FeesStructure />
      <Enrollment />
      <Address />
      <Testimonials />
      <WhyEnrol />
      <Registration />
    </>
  );
}
