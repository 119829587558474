import React from "react";

const Footer = () => {
  return (
    <section className="section-spacing-50 no-padding-bottom footer">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <ul className=" footer-menu flex">
                <li className="footer-menu-item footer-about">
                  <h3>About Kampala City Grammar School</h3>
                  <div className="about-footer">
                    <p>
                      Kampala City Grammar Nursery, Day and Boarding Primary
                      School is located within Mutundwe residential area in a
                      quiet and safe environment. We are a Unique Educational
                      Community where learners enjoy a Modern and Personalized
                      approach to Education.
                    </p>
                    <p className=" margin-top-10">
                      The school has 3 sections of Preschool, Nursery and
                      Primary section to maximize the academic foundation of
                      your child. Both Nursery and Primary sections of the
                      school are licensed and have EMIS Numbers; 1A003412 and
                      2A012972 respectively.
                    </p>
                  </div>
                </li>
                <li className="footer-menu-item footer-nav">
                  <h3>Useful Links</h3>
                  <ul className="footer-nav-menu">
                    <li className="footer-nav-menu-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="footer-nav-menu-item">
                      <a href="/gallery">Gallery</a>
                    </li>
                    <li className="footer-nav-menu-item">
                      <a href="/kcg-staff">School Staff</a>
                    </li>
                    <li className="footer-nav-menu-item">
                      <a href="/admission">Admission Procedure</a>
                    </li>
                    <li className="footer-nav-menu-item">
                      <a href="/contact">Contact Us &amp; Directions</a>
                    </li>
                    <li className="footer-nav-menu-item">
                      <a href="/headteacher-messages">Head Teacher's Message</a>
                    </li>
                    <li className="footer-nav-menu-item">
                      <a href="/fees-structure">
                        Fees Structure &amp; Sections
                      </a>
                    </li>
                    <li className="footer-nav-menu-item">
                      <a href="/about">About School &amp; Foundation</a>
                    </li>

                    <li className="footer-nav-menu-item">
                      <a href="/calendar">School Programs and Calendar</a>
                    </li>
                    {/*
                    <li className="footer-nav-menu-item">
                      <a href="/#">Frequently Asked Questions</a>
                    </li>
                    */}
                    <li className="footer-nav-menu-item">
                      <a href="/#whyenroll">Why enroll your child with KCG</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="copyright margin-top-20 center uppercase">
              <p>
                &copy; 2023 Kampala city grammar nursery day &amp; boarding
                primary school ltd, All rights reserved
              </p>
            </div>
            <div className="designer section-spacing-10 uppercase">
              <div className="design-inner center">
                <p>
                  Website designed by{" "}
                  <a href="https://ntuna.com" className="crimson">
                    NTUNA.COM
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
